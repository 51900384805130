// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-facts-js": () => import("/opt/build/repo/src/pages/facts.js" /* webpackChunkName: "component---src-pages-facts-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("/opt/build/repo/src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-product-js": () => import("/opt/build/repo/src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-product-mypaci-js": () => import("/opt/build/repo/src/pages/product/mypaci.js" /* webpackChunkName: "component---src-pages-product-mypaci-js" */),
  "component---src-pages-reviews-js": () => import("/opt/build/repo/src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */)
}

